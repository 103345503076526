<script setup>
import { insertBanner, updateBanner, getBanner } from '@//api/admin/settings'
import FileAPI from '@/api/fileAPI'
import LoadingVue from '@/components/ui/Loading.vue'
import { ref, onMounted, getCurrentInstance } from 'vue'
import { useRoute, useRouter } from 'vue-router'

const app = getCurrentInstance()
const getDate = app.appContext.config.globalProperties.$getDate

const imageFile = ref(null)
const formRef = ref(null)
const isLoading = ref(false)

const imagePreview = ref('')
const route = useRoute()
const router = useRouter()

const id = route.params.id

const imageAPI = new FileAPI('images')
const boardData = ref({
  boardName: 'banner',
  boardId: id ?? 0
})

const goBack = () => {
  router.replace('/admin/banners')
}

const imageUploaded = (e) => {
  const input = e.target
  if (input.files.length === 0) return (imagePreview.value = '')

  const file = input.files[0]
  if (file) imageFile.value = file
  imagePreview.value = URL.createObjectURL(imageFile.value)
}

const imageUpload = async (boardData) => {
  await imageAPI.deleteAll(boardData)

  await imageAPI.add(imageFile.value)
  return imageAPI.send(boardData, { fullUrl: true })
}
const update = async (data) => {
  const fileUrls = await imageUpload(boardData.value)
  const param = { ...data, imgUrl: fileUrls[0] }

  return await updateBanner(param, boardData.value.boardId)
}
const insert = async (data) => {
  const result = await insertBanner(data)
  if (result?.code) return alert(result.message)

  if (!imageFile.value) return result

  boardData.value.boardId = result.bannerId
  const fileUrls = await imageUpload(boardData.value)

  return await updateBanner({ ...data, imgUrl: fileUrls[0] }, boardData.value.boardId)
}

const formSubmitHandler = async (e) => {
  isLoading.value = true

  const form = formRef.value

  const title = form.title.value
  const startDate = form.startDate.value
  const endDate = form.endDate.value
  const link = form.link.value

  const data = {
    title,
    startDate,
    endDate,
    link
  }

  const result = id ? await update(data) : await insert(data)

  if (result?.code) return alert(result.message)

  alert('저장되었습니다.')
  goBack()
  return (isLoading.value = false)
}

const loadData = async (id) => {
  isLoading.value = true

  const data = await getBanner(id)

  const form = formRef.value
  form.title.value = data.title
  form.startDate.value = getDate(data.startDate)
  form.endDate.value = getDate(data.endDate)
  form.link.value = data.link

  const imageName = data.imgUrl?.split('/').at(-1)
  if (!imageName) {
    return (isLoading.value = false)
  }
  const imgSrc = await imageAPI.createObjectURL(imageName, boardData.value.boardName, boardData.value.boardId)
  imagePreview.value = imgSrc

  imageFile.value = imageAPI._blobMap[imageName]

  isLoading.value = false
}

onMounted(async () => {
  if (id) await loadData(id)
})
</script>

<template>
  <LoadingVue v-if="isLoading" />
  <form class="wf__admin-content" ref="formRef">
    <div class="wf__button-group">
      <button type="button" class="e-btn wf__btn btn-primary" @click="goBack">목록</button>
      <button type="button" class="e-btn wf__btn btn-success" @click="formSubmitHandler">저장</button>
    </div>
    <div class="adminTable">
      <table>
        <colgroup>
          <col width="10%" />
          <col width="40%" />
          <col width="10%" />
          <col width="40%" />
        </colgroup>

        <tbody>
          <tr>
            <th>제목</th>
            <td><input type="text" name="title" class="wf__admin-input" placeholder="" /></td>
          </tr>
          <tr>
            <th>시작일</th>
            <td><input type="date" name="startDate" class="wf__admin-input" placeholder="" /></td>
            <th>종료일</th>
            <td><input type="date" name="endDate" class="wf__admin-input" placeholder="" /></td>
          </tr>
          <tr>
            <th>링크</th>
            <td colspan="3"><input type="text" name="link" class="wf__admin-input w-full" placeholder="" /></td>
          </tr>
          <tr>
            <th>이미지</th>
            <td colspan="3"><input type="file" name="img" class="wf__admin-input" placeholder="" @change="imageUploaded" /></td>
          </tr>
          <tr>
            <th>미리보기</th>
            <td colspan="3">
              <div class="wf__image-preview">
                <img :src="imagePreview" alt="" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </form>
</template>

<style scoped>
.w-full {
  width: 100%;
}
.wf__admin-content {
  max-width: 1280px;
}
.wf__button-group {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  margin-bottom: 1rem;
}
.wf__admin-input {
  outline: none;
  border: 1px solid var(--wf-gray-color);
  border-radius: 0.25em;
  padding: 0.25em 0.5em;
}
.adminTable th {
  text-align: center;
}
.wf__image-preview {
  max-width: 762px;
  min-height: 320px;
}
.wf__image-preview img {
  width: 100%;
}
</style>
